.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3vmin;
  padding-bottom: 100px;
}

.App-logo {
  height: 11vmin;
  pointer-events: none;
  position: absolute;
  left: 2vmin;
  top: 3vmin;
}

.login-logout {
  background-color: white;
  border: none;
  font-size: 2vmax;
  position: absolute;
  top: 1vmin;
  right: 2vmin;
}

button {
  display: inline-block;
  max-width: 300px;
  width: 13vmax;
  background-color: white;
  font-size: medium;
  color: rgb(26, 80, 161);
  border: none;
}

.rnd {
  text-align: left;
}

.clear {
  text-align: right;
}

button:hover {
  text-decoration: underline;
}

button:active {
  color: rgba(26, 80, 161, 0.425);
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  margin-top: 2em;
}

.info div {
  align-items: center;
  margin-top: 0.25em;
  flex-wrap: wrap;
}

.info .protocol {
  margin-top: 2vmax;
  margin-bottom: -0.7vmax;
}

.info .text-with-label {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}


@media (min-width: 768px) {
  .info {
    /* margin-left: -10vmin; */
  }

  .info .questions label {
    width: 30vmax;
    display: inline-block;
    /* text-align: end; */
    /* margin-right: 0.5em; */
    font-size: 0.9em;
    padding-bottom: 0.3em;
  }

  .info .questions textarea {
    width: 25vmax;
    position: relative;
    height: auto;
    font-size: 0.6em;
    resize: none;
    padding: 0.1vmin;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .info .questions label {
    width: 100%;
    display: block;
    text-align: start;
    margin-right: 0;
    font-size: 1em;
  }

  .info .questions textarea {
    width: 100%;
    position: relative;
    font-size: 0.8em;
    resize: none;
    padding: 0.1vmin;
  }
}

.diagnosis-window {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 30vh;
  overflow-y: auto;
  background-color: #f0f0f0;
  padding: 8px;
  z-index: 9999;
  border: 2px solid #535353;
}

@media (min-width: 768px) {
  .diagnosis-window {
	font-size: 2em;
  }
}

@media (max-width: 767px) {
  .diagnosis-window {
	font-size: 1em;
  }
}